import React, { useState } from 'react'
import styled from '@emotion/styled'

const StyledGallery = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s;
  opacity: 1;
`
const Control = styled.div`
  font-size: 30px;
  padding: 1rem;
`
const Gallery = ({ images, index, closeGallery }) => {
  const [currentIndex, setCurrentIndex] = useState(index ?? 0)

  const goPrev = (e) => {
    e.stopPropagation()
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    } else {
      setCurrentIndex(images.length - 1)
    }
  }

  const goNext = (e) => {
    e.stopPropagation()
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      setCurrentIndex(0)
    }
  }
  return (
    <StyledGallery
      onClick={() => {
        closeGallery()
      }}
    >
      <Control onClick={goPrev}>&lt;</Control>
      <img width="50%" src={images[currentIndex].src} />
      <Control onClick={goNext}>&gt;</Control>
    </StyledGallery>
  )
}

export default Gallery
