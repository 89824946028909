import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Gallery from './gallery'

const MotionItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40rem;
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 5rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    height: 15rem;
  }

  img {
    height: 100%;
    object-fit: contain;
  }
`

const ImageContainer = styled.div`
  height: 100%;
  width: 75%;
  text-align: center;
`

const Title = styled.span`
  margin-top: 0.72rem;
`

const defaultImage = [
  {
    src: `//via.placeholder.com/500x300.jpeg`,
  },
]

const StillItem = ({ images, id }) => {
  const [galleryOpen, openGallery] = useState(false)

  images =
    (images && images.map((img) => ({ src: img.fixed.src }))) || defaultImage
  const [imageIndex, setImageIndex] = useState(0)
  const [imagesPreloaded, setImagesPreloaded] = useState(false)
  const [loadedImages, setLoadedImages] = useState(images)

  const preloadImages = async () => {
    const promises = images.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = image.src
        img.onload = () => {
          resolve(img)
        }
      })
    })

    const loadedImages = await Promise.all(promises)
    setLoadedImages(loadedImages)
    setImagesPreloaded(true)
  }

  const changeImage = (e) => {
    let x
    switch (e.type) {
      case 'touchmove':
        x = e.nativeEvent.pageX
        break
      case 'mousemove':
        x = e.nativeEvent.offsetX
        break
    }
    if (imagesPreloaded) {
      const percentage =
        (x / e.nativeEvent.target.clientWidth) * images.length - 1
      if (percentage >= 0 && percentage <= 100)
        setImageIndex(Math.floor(percentage))
    } else {
      preloadImages()
    }
  }

  useEffect(() => {
    if (!imagesPreloaded) {
      preloadImages()
    }
  }, [imagesPreloaded, preloadImages])

  return (
    <>
      <MotionItem
        key={id}
        onClick={() => {
          openGallery(true)
        }}
      >
        <ImageContainer onTouchMove={changeImage} onMouseMove={changeImage}>
          <img
            src={
              loadedImages[
                imageIndex >= 0 && imageIndex <= loadedImages.length - 1
                  ? imageIndex
                  : 0
              ].src
            }
          />
        </ImageContainer>
      </MotionItem>
      {galleryOpen && (
        <Gallery
          images={loadedImages}
          index={imageIndex}
          closeGallery={(e) => {
            openGallery(false)
          }}
        />
      )}
    </>
  )
}

export default StillItem
