import React, { useState } from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CategoryItem from '../components/still'

const MotionContainer = styled.div`
  margin-top: 3.13rem;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  &:after {
    content: ' ';
    flex: auto;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
`

const MotionPage = ({ data }) => {
  const allContentfulStills = data.allContentfulStills.edges
  return (
    <Layout>
      <SEO title="Motion" />
      <MotionContainer>
        {allContentfulStills.map(({ node: category }) => (
          <CategoryItem key={category.id} {...category} />
        ))}
      </MotionContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query StillPageQuery {
    allContentfulStills {
      edges {
        node {
          title
          id
          images {
            fixed(width: 1000) {
              src
            }
          }
        }
      }
    }
  }
`

export default MotionPage
